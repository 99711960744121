/* CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Normalize.css */
body,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: var(--main-font);
  text-shadow: 2px 2px black;
}

body {
  color: var(--main-color);
  background-image: url('./assets/graphic/background.webp');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: var(--black-color);
  overflow-x: hidden;
}

p {
  text-shadow: 2px 2px black;
}

/* General Variables */
:root {
  --main-font: 'Montserrat', sans-serif;
  --secondary-font: 'Roboto Slab', sans-serif;
  --main-color: #fff;
  --silver-color: silver;
  --black-color: #000;
}

/* Font Face */
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./RobotoSlab-VariableFont_wght.ttf') format('truetype');
}

/* Utility Classes */
.text-center {
  text-align: center;
}

/* Links */
a {
  text-decoration: none;
}

/* Name Wrapper */
.name_wrapper {
  width: 100vw;
}

/* Custom styles for animated text */
.animated_text h1 {
  font-size: 250%;
}

/* Image Wrapper */
.img_wrapper {
  margin: -15vh 0 0 0;
}

.img_wrapper img {
  width: 20vw;
  height: 20vw;
  min-height: 150px;
  min-width: 150px;
  max-height: 250px;
  max-width: 250px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border: 3px solid rgba(0, 0, 0, 0.419);
  border-radius: 50%;
}

/* Full Height */
.full-height {
  min-height: 100vh;
  min-width: 100vw;
}

/* Social Icon */
.social-icon i {
  font-size: 2.5rem;
  border: 2px solid var(--silver-color);
  border-radius: 50%;
  padding: 0.5rem;
  transition: all 0.3s ease;
  color: var(--silver-color);
}

.social-icon:hover i {
  background-color: var(--black-color);
}

/* Navbar */

.above_all{
  z-index:999;
}
.letter_large {
  font-size: 250%;
  text-transform: uppercase;
  background-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-stroke: greenyellow;
  -moz-text-stroke:greenyellow;
  -webkit-text-stroke-width: 3px;
  -moz-text-stroke-width: 3px;
}

/* Column Large 4 */
.col-lg-4 {
  font-weight: 600;
  font-size: larger;
}

.col-lg-4 h1 {
  text-rendering: optimizeLegibility;
  font-weight: 900;
  font-size: 200%;
  text-shadow: 4px 4px black;
}

.col-lg-4 p {
  text-rendering: optimizeLegibility;
  color: silver;
  font-size: 125%;
  letter-spacing: .1em;
  text-shadow: -1px -2px 3px #111, 3px 3px 3px #1c1b1b;
}

.col-lg-4 span {
  color: #48ff00;
}

/* Download Button */
.download_btn {
  width: 136px;
}

.download_btn a {
  color: yellowgreen;
}

/* Opacity 80 */
.opacity-80 {
  opacity: 0.8;
}
/* About */
.skills img {
  width:75px;
}

/* Portfolio Icons */
.portfolio_icons {
  color: white;
}
.custom_headlines
{
  letter-spacing: 3px;
  font-weight: 600;
}
/* Glow on Hover */
.glow-on-hover {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  opacity: 1;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing 20s linear infinite;
          animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* Keyframes */
@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


/* Custom Top Padding */
.custom-top-padding {
  padding-top: 10vh;
}

/* Contact */
.form-control {
  width: 20vw;
  min-width: 280px;
  background-color: transparent;
  border: 3px solid rgb(42, 42, 42);
  margin-bottom: 1rem;
  opacity: 0.8;
  box-shadow: -5px -6px 33px -2px rgba(0, 0, 0, 0.31) inset;
  color: #fff;
}

#contact .form-control::-webkit-input-placeholder {
  color: var(--silver-color);
  opacity: 1;
}

#contact .form-control::-moz-placeholder {
  color: var(--silver-color);
  opacity: 1;
}

#contact .form-control:-ms-input-placeholder {
  color: var(--silver-color);
  opacity: 1;
}

#contact .form-control::-ms-input-placeholder {
  color: var(--silver-color);
  opacity: 1;
}

#contact .form-control::placeholder {
  color: var(--silver-color);
  opacity: 1;
}

.form-control:focus {
  border-color: var(--silver-color);
  box-shadow: none;
  background-color: transparent;
  color: var(--silver-color);
}

.form-control .btn {
  background-color: transparent;
  border: 5px solid rgb(70, 70, 70);
  border-radius: 15px;
  color: var(--silver-color);
}

.form-control .btn:hover {
  background-color: transparent;
  border: 5px solid var(--silver-color);
  border-radius: 15px;
}

.form-control .btn:active {
  background-color: var(--silver-color);
  border: 5px solid rgb(255, 255, 255);
  border-radius: 15px;
  color: var(--black-color);
}

.contact-info-container {
  -webkit-backdrop-filter: blur(8px) saturate(124%);
          backdrop-filter: blur(8px) saturate(124%);
  background-color: rgba(17, 25, 40, 0.35);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 20px;
}

.box_shadow {
  box-shadow: 14px 5px 13px 0px rgba(0, 0, 0, 0.65);
}

.gradient {
  background: linear-gradient(to right, #000000, #333333, #666666);
}

/* Media Queries */
@media (min-width: 1200px) {
  .col-xl-4 {
    flex: 0 0 auto;
    width: 27.5%;
  }
}

@media (max-width: 768px) {
  .custom-navbar .navbar-nav {
    justify-content: center;
    width: 100%;
  }

  .container-fluid {
    flex-direction: column;
  }

  .name_wrapper {
    order: 1;
    justify-content: center;
  }

  .navbar-container {
    width: min-100vh;
    order: 2;
  }

  .col-xl-4 {
    width: 20%;
  }

  .nav-link {
    justify-content: center !important;
  }

  .nav-link .btn {
    padding: 3px;
    font-size: smaller;
  }

  .nav-link {
    margin: 0;
    justify-content: center;
  }

  .name_wrapper {
    display: flex;
    width: 100%;
  }

  .custom-top-padding {
    padding-top: 35vh;
  }

  .custom-top-padding img {
    margin-top: 20vh;
  }

  .animated_text h1 {
    font-size: 200%;
  }

  .social-icon i {
    font-size: 1rem;
  }

  .col-lg-4 h1 {
    font-size: 110%;
  }

  .col-lg-4 p {
    font-size: 1rem;
  }

  .contact-info-container p {
    font-size: smaller;
  }
}